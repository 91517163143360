<template>
  <div class="doc-header">
    <div :class="{'doc-header-inner': mounted}"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      mounted: false
    }
  },
  mounted () {
    this.mounted = true
  }
}
</script>

<style lang="stylus">
.doc-header
  background #f2f5f6 url('../../assets/biobank-imgs/biobank-0-small.png') no-repeat
  background-size cover
  height 400px

.doc-header-inner
  background url('../../assets/biobank-imgs/biobank-0.png') no-repeat
  background-size cover
  height 400px
</style>

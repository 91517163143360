<template>
  <div class="doc-body view-padding">
    <div class="doc-info">
      <p><b>易助手®--临床生物样本库信息管理系统</b>（软著登字第0734748号）由<u>上海徽华医药科技有限公司</u>自主开发，是国内最早一批专注于临床生物样本库（BioBank）建设领域相关产品。实现了临床生物样本库建设软件硬件一体化，在江苏海门建立冻存耗材生产基地，为用户定制生产冻存架、冻存盒和冻存管；与中科都菱合作为用户定制BioBank专属超低温冻存系统；整合美国贝迪冷冻标签打印系统、Terra Master云端数据存储系统、无线冷链温度监控系统、样本自动分装系统和全自动核酸提取系统，并通过“银杏叶学术讲座”平台指导临床医师规范化开展科研并合理高效使用临床生物样本库资源，同时建立规范可信的实验样本检测中心，2015年1月在国内创立“银杏叶生物样本库产业联盟”，为广大用户在标本入库前、入库中和入库后提供一整套可行可信的解决方案，有效提高了广大医院单位和临床医生的科研质量和水平。</p>
      <h2>银杏叶-易助手®样本存储与病例随访功能特色</h2>
      <ul>
        <li>独创数据安全与备份解决方案，保证生物样本库数据永久安全有效，防误删改数据，防电脑坏死。</li>
        <li>全面支持样本存储与病例随访，让两者完美对接。</li>
        <li>多中心多用户分级授权管理，有效融合云存储，方便课题团队合作。</li>
        <li>开放式个性化自定义定制开发，用户自主权不受控制。</li>
      </ul>
      <h2>科研管理：全面、高效、简单、易用</h2>
      <ul>
        <li>有效整合患者管理、样本管理及随访管理，是您一站式科研工作助手。</li>
        <li>采用图形化、流程化界面风格，让您可以快速存储、查询和导出所关注的科研资料。</li>
        <li>按照一线临床医生的需求进行研发，十多年医学科研管理经验的结晶，让科研信息管理简单而高效。</li>
      </ul>
      <h3>一、样本管理：快速存取与定位、图形作操作、标准化编码</h3>
      <ul class="index">
        <li>图形化样本出入库方式，让您所有操作简单、形象、直观。</li>
        <li>具有打印清单功能，您可以对照出入库清单到冰箱中快速找到您需要的样本。</li>
        <li>快速样本定位，您可以按我的样本、当日入库、操作类型、样本类型等多种常用条件查询检索方式，还可以通过增加高级查询条件的复杂搜索功能，让您快速从上千或数万样本库中立即定位您关心的样本信息。</li>
        <li>全生命周期样本管理，您可随时知道其使用状态，虚拟入库、实体入库、虚拟出库、实体出库、样本用完、样本删除。</li>
        <li>方便灵活的样本编码方式，您可采用默认国家标准，也可根据科研的需要自己定义样本编码。批量样本出入库，提高您样本管理效率。</li>
        <li>样本可根据不同类型设置不同的默认参数，实现样本管理自定义。</li>
        <li>系统能够支持SPREC编码的使用和管理。</li>
      </ul>
      <h3>二、随访管理：按科研计划组织、灵活定制、方便导出</h3>
      <ul class="index">
        <li>定制随访功能：临床随访信息个性化定制，用户可根据自身需求定制相应的随访计划，对于其每个计划项科研选择不同类型表单，比如CRF格式、字符型、数值型、检验检查类型等。</li>
        <li>随访导出功能：随访信息可根据用户要求随时批量集中导出Excel格式，用于统计分析。</li>
        <li>随访提醒功能：预设患者随访周期，预设时间提前管理者，可绑定手机短信提醒，高校管理患者定期随访。</li>
        <li>随访记录查询功能：您既可以按常用条件检索，又可以按高级条件组合式查询您所关心的随访记录，极大地满足了科研统计查询需求，查询结果可以批量导出Excel格式，用来统计分析。</li>
      </ul>
      <h3>三、患者管理：批量导出导入、快速查询定位</h3>
      <ul class="index">
        <li>支持患者信息的Excel批量导出、导入，方便您对新老数据进行迁移。</li>
        <li>患者信息的快速定位，您可以采用多种查询关键字快速查询定位关心的患者。</li>
        <li>支持患者患者家系管理，实现样本与病例对照</li>
      </ul>
      <h3>四、文件管理：图片、文本、音频、视频多格式文件格式存储支持</h3>
      <ul class="index">
        <li>图片、文本、音频、视频存储支持，多角度立体保存患者的病史资料。</li>
        <li>支持直接接入扫描仪或摄像头快速采集图像，扫描检验检查报告单。</li>
        <li>快速调用病例图片、文本、音频或视频，全方位高效管理病例资料。</li>
      </ul>
      <h3>五、容器管理：多模型支持、图形化展示、使用率统计</h3>
      <ul class="index">
        <li>通用化的容器模型定义，支持您需要的每一种存储容器。</li>
        <li>图形化的存储单元展示，便于您像操作实体容器一样进行存取操作。</li>
        <li>实时统计容器使用率，便于您了解容器使用状况，有效进行样本存取。</li>
      </ul>
      <h3>六、数据安全管理：独创备份机制保障数据安全</h3>
      <ul class="index">
        <li>采用周月等定期数据备份机制，保障您的数据安全。</li>
        <li>独创的多版本备份方案，轻松让您找回一周、一个月、甚至是一年前的数据。</li>
      </ul>
      <h3>七、授权管理：功能与资源双重授权、分级配置、保证个人隐私和数据安全</h3>
      <ul class="index">
        <li>可以对系统中不同身份用户设置不同功能权限，方便您对医院、科室、课题组、个人分级配置，便于科研的个人或团队化工作。</li>
        <li>可以对私有的冰箱、冻存架、冻存盒、随访项目、患者、随访记录添加“安全锁”，设置访问权限，保障您的个人隐私和数据安全。</li>
        <li>完善的多用户登录使用方案，支持云存储，便于多个课题组远程合作共同管理数据库。</li>
      </ul>
      <h3>八、日志管理：操作全过程记录</h3>
      <ul class="index">
        <li>详细的样本操作日志管理，用户可以追踪样本采集、处理及使用的全过程。</li>
        <li>完备的系统操作日志管理，用户可以追溯操作日志。</li>
      </ul>
      <h3>九、医院信息系统对接</h3>
      <ul>
        <li>支持医院第三方软件数据对接，包括但并不限于HIS、LIS、EMR等。</li>
      </ul>
    </div>
    <div class="doc-page"><img src="../../assets/biobank-imgs/biobank-1.png" alt=""></div>
    <div class="doc-page"><img src="../../assets/biobank-imgs/biobank-2.png" alt=""></div>
    <div class="doc-page"><img src="../../assets/biobank-imgs/biobank-3.png" alt=""></div>
    <div class="doc-page"><img src="../../assets/biobank-imgs/biobank-4.png" alt=""></div>
    <div class="doc-page"><img src="../../assets/biobank-imgs/biobank-5.png" alt=""></div>
    <div class="doc-page"><img src="../../assets/biobank-imgs/biobank-6.png" alt=""></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      mounted: false
    }
  },
  mounted () {
    this.mounted = true
  }
}
</script>

<style lang="stylus">
.doc-body
  padding-top 30px
  padding-bottom 30px
  &:after
    content: "."
    visibility: hidden
    display: block
    height: 0
    clear: both
.doc-page
  margin 20px 0
  /*box-shadow 0 0 10px rgba(0, 0, 0, .25)*/
  img
    width 100%
.doc-info
  margin 20px 0
  ul
    margin-top 15px
    margin-bottom 15px
    &.index
      list-style-type decimal
    li
      line-height 28px
  p
    text-indent 32px
    line-height 28px
  h2
    font-size 22px
    height 28px
    line-height 28px
    margin-top 18px
    margin-bottom 0
  h3
    text-indent 20px
    font-size 18px
    height 22px
    line-height 22px
    margin-top 16px
    margin-bottom 0
</style>

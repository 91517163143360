<template>
  <div class="jyh-contact">
    <!-- <doc-header></doc-header> -->
    <doc-body></doc-body>
    <y-footer></y-footer>
  </div>
</template>

<script>
import DocHeader from '../components/doc/DocHeader.vue'
import DocBody from '../components/doc/DocBody.vue'
import YFooter from '../components/Footer.vue'
export default {
  components: { DocHeader, DocBody, YFooter }
}
</script>

<style lang="css">
</style>
